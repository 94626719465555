.product-table-container{

  display: flex;
  align-items: center;
  min-height: 120px;

  &__search-container{
    padding: 24px 24px 0;
  }

  &__actions{
    padding: 0 24px 24px;
  }
}
