@use '../../../../../../../styles/colors.scss';

.delivery-benefits {
  display: flex;
}

.delivery-benefit {
  padding: 8px 24px;
  margin-right: 2px;
}

.delivery-card {
  margin-bottom: 24px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);

  &_isChosen{
    border: 1px solid colors.$slightly-gray;
    box-shadow: none !important;
  }

  &__logo {
    font-size: 24px;
    color: colors.$white;
    height: 40px;
    width: 40px;
    background: colors.$strong-gray;
    display: inline-block;
    text-align: center;
    padding: 6px;
    margin-right: 16px;
  }

  &__main-info {
    display: flex;
    align-items: center;
  }

  &__layout{
    display: flex;
    padding: 24px 24px 8px;
  }

  &__main-content{
    width: 482px;
  }

  &__sum {
    width: 158px;
  }

  &__rating-container{
    display: flex;
    align-items: center;
  }

  &__label{
    display: flex;
    justify-content: space-between;
    width: 590px;
  }

  &__confirmButton{
    padding: 16px 24px 12px;
  }
}

.route-card {
  position: relative;

  &__duration{
    position: absolute;
    left: 42%;
  }
}

.route {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-end;
  width: 512px;
  flex-wrap: wrap;
  gap: 24px 0;
  position: relative;
}

.point {

  &:not(&_fullWidth){
    width: 148px;
  }

  &_fullWidth {
    &:not(:last-child){
      flex: 1;
    }
  }

  &:last-child {
    text-align: right;
    width: 74px;

    .point__text {
      position: relative;
      left: -75px;
      width: 96px;
    }

    .point__plan {
      width: 20px;
    }
  }

  &:nth-child(3n + 3):not(:nth-last-child(2)) {
    .point__plan {
      width: 168px;
    }
  }

  &__img {

    &:not(&_empty){
      border: 1px solid colors.$blue;
      color: colors.$blue;
      border-radius: 50%;
      min-width: 20px;
      padding: 2px;
    }

    height: 20px;
    display: block;
    font-weight: 900;
    text-align: center;
    margin-top: 6px;
    font-size: 12px;
  }

  &__customs-container{
    position: absolute;
    top: 36px;
  }

  &__location{
    display: block;
  }

  &__dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: colors.$blue;
    display: block;
    margin: 4px;
  }

  &__plan {
    display: flex;
    position: relative;

    &_fixed{
      width: 148px;
    }
  }

  &__deliverySign {
    position: absolute;
    top: -12px;
    left: 50%;
    color: rgba(25, 118, 210, 0.5);
    margin-left: -6px;
  }

  &__line{
    background: rgba(25, 118, 210, 0.5);
    width: 100%;
    height: 1px;
    margin-top: 16px;
    position: relative;

    &-arrow{
      position: absolute;
      right: 0;
      top: -3px;
    }
  }

  &__customs{
    position: absolute;
    bottom: -36px;
    left: -2px;
  }

  &__flag-container{
    display: flex;
  }
}
