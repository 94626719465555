@use '../../../../../../../../../../../styles/colors';

.products {
  &__item-completed, &__add-form {
    margin-bottom: 24px;
  }

  &__add-button{
    margin-top: 24px;
    padding: 24px;
    background: colors.$grey-50;
    border: dashed colors.$slightly-gray;
  }

  &__item-name{
    line-height: 1.6;
    font-weight: 500;
  }

  &__item__link{
    cursor: pointer;
  }
}
