@use '../../../../../../../styles/colors.scss';

.file-upload{

  &__container, &__file {
    position: relative;
    border: 1px dashed colors.$slightly-gray;
    margin-top: 24px;
    text-align: center;
  }

  &__file {
    background: colors.$light-beige;
    text-align: left;
    padding: 24px 24px;
  }

  &__container {
    padding: 24px 16px;
  }

  &__image{
    width: 160px;
    margin-right: 24px;
  }

  &__image-container{
    display: flex;
  }

  &__text{
    max-width: 65%;
    margin-top: 54px;
  }

  &__label{
    color: colors.$light-gray;
    font-size: 12px;
    position: absolute;
    top: -8px;
    left: 12px;
    background: white;
    padding: 0 4px;
  }

  &__link{
    color: colors.$blue-dark;
    border-bottom: 1px solid colors.$blue-dark;
  }

  &__names{
    list-style: none;
  }

  &__icons{
    position: absolute;
    top: 28px;
    right: 14px;
  }
}
