@use '../../../../../../../../../../../../../styles/colors.scss';

$default-grid-template: "name name"
"row-code row-code"
"common common"
"unit_price price"
"common_package palletes"
"row row";

$package-grid-template:
"net_weight_cartoon gross_weight_cartoon";

$buttons-grid-template:
"divider divider"
"row-buttons row-buttons";

$palletes-grid-template: "palletes-type palletes-type"
"palletes-fields palletes-fields"
"palletes-two_tiers palletes-two_tiers";

$additional-grid-template:
"notification notification";

$common-grid-template:
"row-code row-code";

.add-product {

  background: colors.$grey-50;
  border: dashed colors.$slightly-gray;
  padding: 24px;

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 24px;

    &:not(.add-product__form_isCommon) {
      grid-template-areas: $default-grid-template $package-grid-template $buttons-grid-template;

      &.add-product__form_hasPalletes{
        grid-template-areas: $default-grid-template $package-grid-template $palletes-grid-template $buttons-grid-template;
      }
    }

    &.add-product__form_isAdditional{
      grid-template-areas: $default-grid-template $package-grid-template $additional-grid-template $buttons-grid-template;

      &.add-product__form_hasPalletes{
        grid-template-areas: $default-grid-template $package-grid-template $palletes-grid-template $additional-grid-template $buttons-grid-template;
      }
    }
  }

  &__form_isCommon {
    grid-template-areas: $default-grid-template $buttons-grid-template;

    &.add-product__form_hasPalletes{
      grid-template-areas: $default-grid-template $palletes-grid-template $buttons-grid-template;
    }

    &.add-product__form_isAdditional{
      grid-template-areas: $default-grid-template $additional-grid-template $buttons-grid-template;

      &.add-product__form_hasPalletes{
        grid-template-areas: $default-grid-template $palletes-grid-template $additional-grid-template $buttons-grid-template;
      }
    }
  }

  &__name { grid-area: name; }
  &__common { grid-area: common; }
  &__unit_price { grid-area: unit_price; }
  &__price { grid-area: price; }
  &__common_package { grid-area: common_package; }
  &__palletes { grid-area: palletes; }
  &__row { grid-area: row; }
  &__row-code { grid-area: row-code; }
  &__divider { grid-area: divider; }

  &__row-buttons { grid-area: row-buttons; }
  &__notification { grid-area: notification; }
  &__net_weight_cartoon { grid-area: net_weight_cartoon; }
  &__gross_weight_cartoon { grid-area: gross_weight_cartoon; }
  &__palletes-type { grid-area: palletes-type; }
  &__palletes-fields { grid-area: palletes-fields; }
  &__palletes-two_tiers { grid-area: palletes-two_tiers; }

  &__country_code, &__addButton {
    grid-column: span 2;
  }

  &__row, &__row-code, &__row-buttons, &__palletes-fields, &__common, &__divider {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 24px;
  }

  &_padding{
    padding: 32px 24px;
  }

  &__notification .add-product__addFieldButton{
    margin-top: 32px;
  }

  &__buttons-container{
    display: flex;
    width: 182px;
    justify-content: space-between;
  }

}
