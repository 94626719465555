@use '../../../../../../../../../../../../../styles/colors';

.settings-panel{
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  &__container{
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
  }
}
