@use '../../../styles/colors.scss';

.title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.MuiDialog-insideModal .MuiDialog-paperScrollBody{
  max-width: 688px;
}

.children{
  &:not(&_isSearch) {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    margin-top: 24px;
    background: colors.$white;
  }

  &:not(&_isSearch):not(&_noPadding) {
    padding: 24px;
  }
}
