.dictionary__modal-add-container {
  &_locationType {
    grid-column: span 2;
    grid-template-columns: 1fr;
  }

  &_code{
    display: flex;
  }
}
