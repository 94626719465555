@use '../../../../../../../../../../../styles/colors';

.customs-declaration {

  background: colors.$light-beige;
  padding: 16px 24px 0;
  border: 1px dashed colors.$slightly-gray;

  &_collapsed{
    padding: 16px 24px 8px;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &__title {
    display: flex;
  }

  &__title-row{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left, &-right{
      display: flex;
      align-items: center;
    }

  }

  &__changeVat{
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  &__value-container{
    display: flex;
    align-items: start;
    font-size: 14px;

    &:not(&_certificate):not(&_uniqVatValues){
      text-align: right;
      max-width: 194px;
    }
  }

  &__link {
    cursor: pointer;
  }

  &__linkModalChooseVat {
    display: block;
    cursor: pointer;
  }

  &__container{
    margin-top: 24px;
  }

  &__option{
    display: flex;
    align-items: baseline;
  }

  &__row-buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;
  }

  svg {
    cursor: pointer;
  }

  &__subtitle{
    display: flex;
    justify-content: space-between;
  }

  &__list {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__row {
    margin-bottom: 16px;

    &_small-margin{
      margin-bottom: 8px;
    }
  }

  &__row, &__value-container_uniqVatValues {
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;

    &:not(&_certificate){
      &::before {
        content: '';
        position: absolute;
        left: calc(100% - 100%);
        right: 0;
        top: 14px;
        transform: translateY(-50%);
        background-size: 4px 4px;
        background-repeat: repeat-x;
        display: block;
        width: 100%;
        background-position: 0 50%;
        background-image: radial-gradient(circle, colors.$slightly-gray 40%, transparent 40%);
        height: 1px;
        z-index: 0;
      }
    }

    &_isBig{
      margin-bottom: 20px;
    }


    &_isSmall{
      margin-bottom: 0;
    }

    &_isBig, &_isSmall{
      &::before {
        bottom: 7px;
      }
    }

    .customs-declaration__name, div {
      background: colors.$light-beige;
      z-index: 1;

      &:first-child {
        padding: 0 4px 0 0;
      }
    }

  }

  &__goodsName{
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.42);
    text-decoration-style: dashed;
    text-underline-offset: 4px;
    line-height: 1.6;
    font-weight: 500;
  }

  &__link{
    cursor: pointer;
  }

  &__text-with-tooltip{
    font-weight: 500;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.42);
    cursor: pointer;
  }

  &__tooltip svg{
    width: 18px;
    height: 18px;
  }

  &__link{
    border-bottom: 1px dashed;
    padding-left: 4px;
  }

  &__row-container{
    display: flex;
  }

  &__label-dot{
   padding: 0 8px;
  }

  &__select-change-certificate{
    display: flex;
  }

  &__change-certificate-tooltip{
    margin-right: 8px;
    height: 24px;
  }

  &__subtotal{
    margin-right: 16px;
  }

  &__menu-button{
    margin: 0 8px;
  }
}
