@use '../../../../../styles/colors.scss';

.summary-calculator {
  background: colors.$lime;
  margin-bottom: 32px;

  &__info{
    background: colors.$lime;
    margin: 24px;
    border: 1px solid colors.$slightly-gray;
  }

  &__buttons{
    display: grid;
    gap: 14px 0;
    margin: 24px;
    background: colors.$white;
    border: 1px solid colors.$slightly-gray;
    padding: 24px 24px 24px;

    &:not(&-one){
      grid-template-rows: 1fr 1fr;
    }
  }

  &__actions{
    margin: 24px 24px 0;
    padding-bottom: 24px;
  }

  &__print-version{
    display: none;
  }

  &__sorting{
    display: flex;
    margin: 24px;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-left{
      display: flex;
    }

    &-right{
      margin-right: 18px;
    }
  }

  &__table{
    table-layout: fixed;
    width: 100%;
    margin-bottom: 24px;
    border-collapse: collapse;

    th {
      padding: 14px 8px;
    }

    td {
      padding: 12px 8px;
    }

    tbody tr:not(:last-of-type), thead tr {
      border-bottom: 1px solid colors.$slightly-gray;
    }

    th, td {
      box-sizing: content-box;

      p {
        text-align: right;
      }

      &:first-child{
        p {
          text-align: center;
        }
        width: 25px;
      }

      &:nth-child(2){
        p {
          text-align: left;
        }
        width: 190px;
      }

      &:nth-child(3), &:nth-child(4){
        width: 80px;
      }

      &:nth-child(5){
        width: 96px;
      }

      &:nth-child(6){
        width: 64px;
        p {
          margin-right: 8px;
        }
      }
    }

  }
}
