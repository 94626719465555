@use '../../../../../../../../../styles/colors';

.preferences-value{
  display: flex;
  align-items: center;
  background: colors.$grey-200;
}

.preferences-completed{
  margin-top: 24px;
  background: colors.$grey-200;
}
