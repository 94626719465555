@use '../../../../../../../../../styles/colors';

.preferences {
  background: colors.$grey-200;
  border: 1px dashed colors.$slightly-gray;
  padding: 24px 24px 20px;
  margin-top: 24px;

  &__list{
    margin: 0;
    padding: 0 0 0 20px;
  }
}
