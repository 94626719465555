.product-table-print{

  table-layout: fixed;
  width: 100%;
  font-size: 8px;
  border-collapse: collapse;

  th, td {
    border: 1px solid black;
    padding: 4px;
  }

  &__head th{
    height: 65px;
    text-align: left;
    box-sizing: content-box;
    font-weight: 500;

    &:first-child, &:nth-child(2){
      text-align: center;
    }

    &:not(:first-child):not(:nth-child(2)){
      writing-mode: vertical-rl;
      transform: rotate(180deg);
    }

    &:first-child{
      width: 8px;
    }

    &:nth-child(2){
      width: 90px;
    }

    &:nth-child(7){
      width: 54px;
    }
  }

  &__body td{
    text-align: right;

    &:first-child, &:nth-child(2){
      text-align: left;
    }
  }

  &__image{
    width: 202px;
  }

  &__hr{
    margin-top: 2px;
    border: 1px solid;
  }

  &_isHorizontal{
    .product-table-print__head th{
      &:nth-child(2){
        width: 290px;
        text-align: left;
      }
    }
  }
}

.product-table-print__container{
  width: 664px;
  display: none;
}

.product-table-print__container_isHorizontal{
  width: 100%;
}

@media print {
  .product-table-print__container{
    display: block;
  }
}
