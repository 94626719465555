.code-table{
  padding: 0 24px;

  &__bold{
    font-weight: 500;
  }

  &__link {
    text-decoration: underline;
    color: inherit;
  }

  &__list{
    list-style: none;
    padding: 0;
    margin-top: 22px;
  }

  td {
    vertical-align: top;
  }
}
