.code-table{
  table-layout: fixed;

  td {
    &:first-child{
      width: 154px;
    }
  }

  &__section-header{
    padding: 32px 24px 16px;
  }

  &__section-bottom{
    padding: 24px 24px 16px;
  }
}
