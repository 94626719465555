@use '../../../styles/colors.scss';

.completed {

  position: relative;

  &:not(&_noBackground){
    background: colors.$light-beige;
  }

  &:not(&_noPadding){
    padding: 24px;
    border: 1px solid colors.$slightly-gray;
  }

  &_noBackground .completed__list-item > p:before{
    background: transparent;
  }

  &__menu{
    position: absolute;
    top: 17px;
    right: 22px;

    &_hasCollapse{
      width: 72px;
      display: flex;
      justify-content: space-between;
    }
  }

  &__list {
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 24px;
    margin-bottom: 0;
    margin-top: 0;

    &-oneRow{
      grid-template-columns: 1fr;
    }

    &-threeRows {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-indent{
      margin-top: 16px;
    }

    &-small{
      width: 100%;
    }

    &-noValues {
      .completed__list-item::before {
        content: none;
      }
    }

    a {
      color: colors.$blue;
      border-bottom: 1px dashed;
    }

  }

  &__list-item{
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;

    > p {
      position: relative;
      z-index: 1;
      background: inherit;

      &:before{
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: colors.$grey-50;
        top: 13px;
        z-index: -1;
      }

      &:first-child{
        max-width: 500px;

        &:before{
          padding-right: 4px;
          left: 0;
        }
      }

      &:nth-child(2){
        text-align: right;
        max-width: 400px;

        &:before{
          padding-left: 4px;
          right: 0;
        }
      }
    }
  }

  &__list-item::before {
    content: '';
    position: absolute;
    left: calc(100% - 100%);
    right: 0;
    top: 14px;
    transform: translateY(-50%);
    background-size: 4px 4px;
    background-repeat: repeat-x;
    display: block;
    width: 100%;
    z-index: 1;
    background-position: 0 50%;
    background-image: radial-gradient(circle, colors.$slightly-gray-border 40%, transparent 40%);
    height: 1px;
  }

  &__caption-row{
    display: flex;
    justify-content: space-between;
    padding: 0 0 2px;
    min-height: 28px;
    max-width: 95%;
  }

  &__chip{
    margin-bottom: 16px;
  }
}



