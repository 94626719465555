.currency-container {
  width: 736px;

  &__search-container{
    padding: 24px 24px 0;
  }
}

.currency-table {
  width: 100%;
  padding: 16px 24px 24px;
  margin-top: 6px;
}
